@view-transition {
    navigation: auto;
  }
 
 
.link-clicked {
  animation: link-click var(--link-timing) ease-in-out;
  pointer-events: none;
}

::view-transition-old(cover),
::view-transition-new(cover) {
  animation-duration: var(--link-timing);
  animation-timing-function: ease-in-out; 
}

::view-transition-old(cover) {
  animation: fade-out .4s, move-down .36s;
  animation-delay: .1s; 
}

::view-transition-new(cover) {
  animation: fade-in .4s, move-up .36s;
}

@keyframes link-click {
  0%, 100% {
    transform: scale(1);
    color: inherit;
  }
  50% {
    transform: scale(1.1);
    color: #007bff;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes move-down {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(1.25rem);
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes move-up {
  from {
    transform: translateY(1.25rem);
  }
  to {
    transform: translateY(0);
  }
}
